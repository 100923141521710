import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyC7H-P1vpGmYfHcEGN_TbCRpRbrZBcLq_s",
    authDomain: "modnutri.firebaseapp.com",
    projectId: "modnutri",
    storageBucket: "modnutri.appspot.com",
    messagingSenderId: "775861395545",
    appId: "1:775861395545:web:0c30ac6090f92fef6e9148"
  };

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };
console.log('Firebase inicializado');


