import React from 'react';
import styled from 'styled-components';
import FeedbackForm from '../components/FeedbackForm';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;



const Home = () => {
  return (
    <Container>
      <FeedbackForm />
    </Container>
  );
};

export default Home;