import React, { useState, useContext } from 'react'
import { FeedbackContext } from '../context/FeedbackContext'
import { Mail, ArrowRight, Send, CalendarDays, Edit3, CheckCircle, Info, AlertCircle } from 'lucide-react'
import Menu from './menu'

const ConfirmationCard = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
    <div className="bg-white p-6 rounded-lg w-full max-w-md">
      <div className="flex flex-col items-center text-center">
        <CheckCircle className="text-green-500 w-20 h-20 mb-6" />
        <h2 className="text-2xl font-semibold mb-4">Solicitação Enviada</h2>
        <p className="text-lg text-gray-600 mb-6">
          Sua solicitação foi enviada para a nossa nutricionista e em até 24 horas sua dieta será alterada com base no seu pedido.
        </p>
        <button
          onClick={onClose}
          className="bg-black text-white py-3 px-6 rounded-md hover:bg-gray-800 transition-colors text-lg font-medium w-full"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
)

export default function NutriMod() {
  const [email, setEmail] = useState('')
  const [diet, setDiet] = useState('')
  const [dietChanges, setDietChanges] = useState('')
  const [showDiet, setShowDiet] = useState(false)
  const [modifyCalories, setModifyCalories] = useState(false)
  const [calories, setCalories] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [error, setError] = useState('')
  const { addPedido } = useContext(FeedbackContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')

    if (!email || !dietChanges) {
      setError('Por favor, preencha todos os campos obrigatórios.')
      return
    }

    if (modifyCalories && !calories) {
      setError('Por favor, selecione as calorias desejadas.')
      return
    }

    try {
      await addPedido({ email, diet, dietChanges, calories })
      setDiet('')
      setEmail('')
      setDietChanges('')
      setCalories('')
      setModifyCalories(false)
      setShowDiet(false)
      setShowConfirmation(true)
    } catch {
      setError('Erro ao adicionar pedido. Por favor, tente novamente.')
    }
  }

  const handleContactClick = () => {
    // Implement contact functionality here
    console.log('Contact button clicked')
  }

  const handleProsseguir = () => {
    if (email) {
      setShowDiet(true)
    } else {
      setError('Por favor, insira seu email antes de prosseguir.')
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Menu onContactClick={handleContactClick} />
      <div className="flex items-center justify-center p-4 pt-20 lg:pt-24">
        <div className="w-full max-w-2xl bg-white p-6 sm:p-8 rounded-lg shadow-md">
          <div className="text-center mb-8">
            <h1 className="text-3xl sm:text-4xl font-bold text-black mb-2">Nutri Inteligente</h1>
            <h2 className="text-xl sm:text-2xl text-gray-600">Modificação de Dieta</h2>
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">Seu email</label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="exemplo@email.com"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black text-base"
                  required
                />
              </div>
            </div>

            {!showDiet && (
              <button
                type="button"
                onClick={handleProsseguir}
                className="w-full bg-black text-white py-3 px-6 rounded-md hover:bg-gray-800 transition-colors flex items-center justify-center text-lg font-medium"
              >
                Prosseguir
                <ArrowRight className="ml-2" size={20} />
              </button>
            )}

            {showDiet && (
              <>
                <div className="space-y-4 bg-gray-100 p-4 sm:p-6 rounded-lg border border-gray-200">
                  <label htmlFor="dietChanges" className="block text-xl font-bold text-black mb-2">
                    Descreva as alterações desejadas na sua dieta
                  </label>
                  <div className="relative">
                    <Edit3 className="absolute left-3 top-3 text-gray-400" size={20} />
                    <textarea
                      id="dietChanges"
                      value={dietChanges}
                      onChange={(e) => setDietChanges(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md h-32 focus:outline-none focus:ring-2 focus:ring-black text-base"
                      placeholder="Ex: Gostaria de substituir o pão integral por tapioca no café da manhã..."
                      required
                      maxLength={1000}
                    />
                  </div>
                  <div className="flex justify-end mt-2">
                    <span className="text-sm text-gray-500">
                      {dietChanges.length}/1000 caracteres
                    </span>
                  </div>
                  <p className="text-sm text-gray-600 mt-2">
                    Por favor, seja específico sobre as mudanças que você deseja na sua dieta. Você tem até 1.000 caracteres para fornecer detalhes.
                  </p>
                </div>

                <div className="flex items-center space-x-3 bg-gray-100 p-4 rounded-lg">
                  <input
                    type="checkbox"
                    id="modifyCalories"
                    checked={modifyCalories}
                    onChange={() => setModifyCalories(!modifyCalories)}
                    className="w-5 h-5 rounded text-black focus:ring-2 focus:ring-black"
                  />
                  <label htmlFor="modifyCalories" className="text-base font-medium text-gray-700">Deseja alterar as calorias da dieta?</label>
                </div>

                {modifyCalories && (
                  <div className="relative">
                    <CalendarDays className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                    <select
                      value={calories}
                      onChange={(e) => setCalories(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-black text-base"
                      required={modifyCalories}
                    >
                      <option value="">Selecione as calorias desejadas</option>
                      <option value="1500">1500 kcal</option>
                      <option value="2000">2000 kcal</option>
                      <option value="2500">2500 kcal</option>
                      <option value="3000">3000 kcal</option>               
                      <option value="3500">3500 kcal</option>
                    </select>
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full bg-black text-white py-3 px-6 rounded-md hover:bg-gray-800 transition-colors flex items-center justify-center text-lg font-medium"
                >
                  Enviar Solicitação
                  <Send className="ml-2" size={20} />
                </button>
              </>
            )}

            {error && (
              <div className="text-red-500 flex items-center bg-red-100 p-3 rounded-md">
                <AlertCircle className="mr-2 flex-shrink-0" size={20} />
                <span className="text-sm">{error}</span>
              </div>
            )}
          </form>

          {showDiet && (
            <div className="mt-6 text-sm text-gray-600 flex items-center justify-center bg-gray-100 p-3 rounded-md">
              <Info className="mr-2 flex-shrink-0" size={20} />
              <span>Sua solicitação será analisada por nossa equipe de nutricionistas.</span>
            </div>
          )}
        </div>
      </div>

      {showConfirmation && <ConfirmationCard onClose={() => setShowConfirmation(false)} />}
    </div>
  )
}

