import React, { createContext, useEffect, useState, useCallback } from 'react';
import { ref, push, onValue, update } from 'firebase/database';
import { auth, db } from '../utils/firebase';

export const FeedbackContext = createContext(null);

export const FeedbackProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [pedidos, setPedidos] = useState([]);
  const [todayPedidos, setTodayPedidos] = useState([]);
  const [totalPedidos, setTotalPedidos] = useState(0);

  useEffect(() => {
    const pedidoRef = ref(db, 'pedidos');

    const unsubscribe = onValue(pedidoRef, (snapshot) => {
      if (auth.currentUser && auth.currentUser.email === 'contanova22@gmail.com') {
        const data = snapshot.val();
        if (data) {
          const currentDate = new Date();
          const pedidoArray = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
            date: new Date(value.date),
            concluido: value.concluido || false
          }));

          setPedidos(pedidoArray);
          setTodayPedidos(pedidoArray.filter(item => 
            item.date.toDateString() === currentDate.toDateString()
          ));
          setTotalPedidos(pedidoArray.length);
        } else {
          setPedidos([]);
          setTodayPedidos([]);
          setTotalPedidos(0);
        }
      }
      setLoading(false);
    }, (error) => {
      console.error("Error fetching pedidos:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addPedido = useCallback(async (pedidoData) => {
    const pedidoRef = ref(db, 'pedidos');
    const newPedido = {
      ...pedidoData,
      date: new Date().toISOString(),
      concluido: false
    };

    try {
      await push(pedidoRef, newPedido);
      console.log(`Pedido Adicionado : ${pedidoData.name}, Date: ${pedidoData.date}`);
    } catch (error) {
      console.error("Error adicionar pedido: ", error);
      throw error;
    }
  }, []);

  const toggleConcluido = useCallback(async (pedidoId, concluido) => {
    const pedidoRef = ref(db, `pedidos/${pedidoId}`);
    try {
      await update(pedidoRef, { concluido });
      console.log(`Pedido ${pedidoId} atualizado. Concluído: ${concluido}`);
    } catch (error) {
      console.error("Error ao atualizar pedido: ", error);
      throw error;
    }
  }, []);

  const value = {
    pedidos,
    todayPedidos,
    totalPedidos,
    addPedido,
    toggleConcluido
  };

  return (
    <FeedbackContext.Provider value={value}>
      {!loading && children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
